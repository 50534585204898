<template>
  <section>
    <div class="all-devices ">
      <b-card no-body>
        <div class="m-2 mb-0">
          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="6"
              sm="12"
              offset-sm="0"
              offset-md="6"
              offset-lg="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchDevices"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @keyup.enter="pageChanged(1)"
                />
                <b-button
                  variant="primary"
                  @click="pageChanged(1)"
                >
                  Search
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-skeleton-table
          v-if="spinner"
          :rows="paginationObject.limit"
          :columns="3"
          :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
        />
        <div
          v-else
          class="heading-elements ml-0"
        >
          <b-table
            id="devices-table"
            :per-page="paginationObject.limit"
            :items="allDevices"
            responsive
            :fields="tableColumns"
            show-empty
            class="float: right"
          >
            <!-- Column: name -->
            <template #cell(Devices)="data">
              <b-link
                :to="{
                  name:'single-device-admin',
                  params:{
                    deviceId: data.item.id.toString(),
                    userId: $route.params.userId,
                    deviceName: data.item.name
                  }
                }"
              >
                <span class="d-block text-nowrap devices">
                  {{ data.item.name }}
                </span>
              </b-link>
            </template>
            <!-- Column: Groups -->
            <template #cell(Group)="data">
              <span
                v-for="device_has_group in data.item.device_has_group"
                :key=" device_has_group.id"
              >
                <div
                  v-if="spinners[data.item.id + '' + device_has_group.device_groups.id]"
                  class="spinner"
                >
                  <b-spinner
                    variant="primary"
                    small
                  />
                </div>
                <b-badge
                  v-else
                  href="#"
                  class="group-badge"
                  variant="light"
                >
                  {{ device_has_group.device_groups.name }}
                </b-badge>
              </span>
            </template>
            <!-- Column: Status -->
            <template
              #cell(BANNER-MODE)="data"
              class="BannerMode con-switch"
            >
              <span class="float-right">
                <div
                  v-if="childSpinner[data.item.mac_address]"
                  style="width: 75px;"
                  class="spinner text-center"
                >
                  <b-spinner
                    variant="primary"
                    small
                  />
                </div>
                <vs-switch
                  v-else
                  disabled
                  :value="data.item.is_banner_ad_mode_enabled"
                  class="switchs"
                  :style="`background-color: ${data.item.is_banner_ad_mode_enabled ? '#00E265' : '#FF0000'};color:white;`"
                >
                  <span
                    slot="on"
                    style="font-weight: bold"
                  >Disable</span>
                  <span
                    slot="off"
                    style="font-weight: bold"
                  >Enable</span>
                </vs-switch>
              </span>
            </template>
          </b-table>
        </div>
      </b-card>
      <div>
        <CustomPagination
          :total="paginationObject.total"
          :per-page="paginationObject.limit"
          :page="paginationObject.offset"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BTable,
  BLink,
  BSpinner,
  BBadge,
  BSkeletonTable,
  BButton,
  BFormInput,
  VBToggle,
  BCard,
} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import CustomPagination from '@/components/common/CustomPagination.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    BBadge,
    BSkeletonTable,
    BButton,
    BFormInput,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      spinner: false,
      searchDevices: '',
      allDevices: [],
      tableColumns: [
        { key: 'Devices' },
        { key: 'Group' },
        {
          key: 'BANNER-MODE',
          thClass: 'pull-right text-right textTransform hide',
        },
      ],
      spinners: {},
      childSpinner: {},
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  async mounted() {
    await this.getAllDevices(this.paginationObject)
  },
  methods: {
    openDevicesGroup() {
      this.$refs['add-devices-group'].show()
    },
    // getSingleDevice(device) {
    //   this.$store.dispatch('devices/getDevice', device)
    //   // console.log(device)
    // },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllDevices(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllDevices(this.paginationObject)
    },
    getAllDevices({ offset, limit }) {
      this.spinner = true
      this.$axios.get(`admin/device/${this.$route.params.userId}?offset=${offset - 1}&limit=${limit}&search_query=${this.searchDevices}`)
        .then(({ data }) => {
          this.allDevices = data.data.results
          this.paginationObject.total = data.data.total
          this.spinner = false
        }).catch(() => this.$swal('Device not found!'))
    },
  },
}
</script>
<style lang="scss">
.all-devices{
  .group-badge{
    color: #000000;
    cursor: default;
    font-size: 12px;
    margin: 5px;
  }
  .device-delete-icon{
    font-weight: 400;
    color: #6e6b7b;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover{
      color: blue;
    }
  }
  .card:last-of-type{
    margin-bottom: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.vs__actions {
  font-weight: 100 !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.devices{
  font-size: 14px;
  line-height: 11px;
  color: #1f58b5;
  font-weight: 500;
  font-family: "Montserrat";
}
</style>
